import { graphql } from "gatsby";
import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { VscListSelection } from "react-icons/vsc";
import config from "../../data/SiteConfig";
import Footer from "../components/Footer/Footer";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import Layout from "../layout";
import * as S from "../styles/styles.css";

function Listing({ pageContext, data }) {
  function renderPaging() {
    const { currentPageNum, pageCount } = pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "/" : `/${currentPageNum - 1}/`;
    const nextPage = `/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    if (pageCount > 1)
      return (
        <S.PagingRow>
          {!isFirstPage && (
            <S.PagingRowLink to={prevPage}>
              <S.PagingRowButton>PREV</S.PagingRowButton>
            </S.PagingRowLink>
          )}
          {[...Array(pageCount)].map((_val, index) => {
            const pageNum = index + 1;
            return (
              <S.PagingRowLink
                key={`listing-page-${pageNum}`}
                to={pageNum === 1 ? "/" : `/${pageNum}/`}
              >
                <S.PageNumber>{pageNum}</S.PageNumber>
              </S.PagingRowLink>
            );
          })}
          {!isLastPage && (
            <S.PagingRowLink to={nextPage}>
              <S.PagingRowButton>NEXT</S.PagingRowButton>
            </S.PagingRowLink>
          )}
        </S.PagingRow>
      );
  }

  const postEdges = data.allMarkdownRemark.edges;
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  function subMenuShow() {
    setSubMenuOpen((prevSubMenu) => !prevSubMenu);
  }

  return (
    <Layout
      location="artworks"
      title="Artworks"
      bodyAttributes={{
        style: "overflow: unset",
      }}
    >
      <S.GalleryRow>
        <S.SubMenuButton
          className="d-none d-md-block"
          onClick={() => {
            subMenuShow();
          }}
        >
          <VscListSelection size={23} />
        </S.SubMenuButton>
        <S.SubMenuCol
          sm={6}
          md={4}
          lg={3}
          xl={2}
          className="d-none d-md-block"
          closed={!subMenuOpen}
        >
          <S.NavLink to="/categories/oil-painting" activeClassName="activeLink">
            <S.CategoryButton className="catButton">
              Oil Painting
            </S.CategoryButton>
          </S.NavLink>
          <S.NavLink activeClassName="activeLink" to="/categories/drawing">
            <S.CategoryButton className="catButton">Drawing</S.CategoryButton>
          </S.NavLink>
          {/* <NavLink to="/categories/tee-shirts">
                <CategoryButton className="catButton">Tee Shirts</CategoryButton>
              </NavLink>
              <NavLink to="/categories/sketches">
                <CategoryButton className="catButton">Sketches</CategoryButton>
              </NavLink> */}
          <S.NavLink activeClassName="activeLink" to="/categories/mixed-media">
            <S.CategoryButton className="catButton">Other</S.CategoryButton>
          </S.NavLink>
        </S.SubMenuCol>
        <Col>
          <S.GalleryRowInnerDiv className="listing-container">
            <div className="posts-container">
              <Helmet title={config.siteTitle} />
              <SEO />
              <PostListing subMenuOpen={subMenuOpen} postEdges={postEdges} />
            </div>
          </S.GalleryRowInnerDiv>
        </Col>
      </S.GalleryRow>
      {renderPaging()}
      <Footer config={config} basicView />
    </Layout>
  );
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            category
            date
          }
        }
      }
    }
  }
`;
